import React from 'react';
import Seo from '../../components/seo';
import PageTitle from '../../components/pagetitle';
import PageContent from '../../components/pagecontent';
import { Link } from 'gatsby';

const Thanks = () => (
  <main className='contact'>
    <Seo title='Thank You ' />
    <PageTitle
      pageTitle="You're Awesome"
      pageTagline='Thank you for your '
      pageHighlight='inquiry'
      pageClone='Success'
    />
    <section className='form-info'>
      <PageContent
        heading='We will return your email within 2 business days'
        contentText='We look forward to working with you.'
      />
      <div className='btn__wrapper'>
        <Link
          title='Back to Homepage'
          className='btn cta__btn transform-scale-h'
          to='/'
        >
          Back to Homepage
        </Link>
      </div>
    </section>
  </main>
);

export default Thanks;
