import * as React from 'react';
import AnimatedTitle from '../../components/animatedtitle';

import './pagecontent.style.scss';

const PageContent = ({ heading, contentText }) => (
  <section className='page-content'>
    <AnimatedTitle animatedTitle={heading} />
    <p>{contentText}</p>
  </section>
);

export default PageContent;
